import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Head from "../components/Head"
import Layout from "../components/Layout"

export const query = graphql`
  query($slug: String!) {
    contentfulProjects(slug: { eq: $slug }) {
      title
      publishedDate(formatString: "YYYY")
      hours
      slug
      usedTech
      githubLink
      hours
      funnyText
      funnyParagraph
      codeLine
      projectLink
      imageVideo {
        file {
          url
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

const Container = styled.div`
  margin-top: 81.5px;
  color: #1c528a;
  p {
    font-family: "Raleway", sans-serif;
    font-size: 1.111rem;
    line-height: 1.7rem;
    // text-align: justify;
  }
  section a {
    font-weight: bold;
    color: #69cdcd;
    &:hover {
      color: #e45865;
      transition: all 0.5s ease-in;
    }
  }
`

const Entities = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  margin: 50px 0;
  h1 {
    font-family: "Playfair Display", serif;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
    color: #1c528a;
    position: relative;
    transition: all 0.3s ease-in-out;
    &::after {
      content: "→";
      color: #e45865;
      position: absolute;
      top: 0;
      left: 110%;
      font-size: 30px;
      @media (max-width: 480px) {
        top: 30%;
        left: 5%;
        font-size: 10px;
      }
      @media (max-width: 1100px) {
        top: 30%;
        left: 5%;
        font-size: 25px;
      }
    }
    &:hover {
      color: #e45865;
    }
  }
  p {
    font-size: 15px;
    margin-bottom: 0;
  }
  @media (max-width: 480px) {
    margin: 40px 0;
    h1 {
      font-size: 20px;
    }
    p {
      font-size: 12px;
    }
  }
`

const StyledEntities = styled(Entities)`
  max-width: 400px;
  margin: 80px auto 0 auto;
  text-align: center;
  padding: 0 15px;
  @media (max-width: 480px) {
    margin-top: 40px;
  }
`

const Project = props => {
  const {
    title,
    publishedDate,
    hours,
    usedTech,
    githubLink,
    projectLink,
    codeLine,
    funnyText,
    funnyParagraph,
    imageVideo,
    body,
  } = props.data.contentfulProjects

  const extension = imageVideo.file.url.split(".").pop()

  return (
    <Layout>
      <Head title={`${title}`} />
      <h1 className="title">{title}</h1>
      <Container
        data-sal="slide-up"
        data-sal-duration="1000"
        data-sal-easing="ease-out-back"
      >
        <section
          dangerouslySetInnerHTML={{
            __html: body.childMarkdownRemark.html,
          }}
        ></section>
        <Entities>
          <div>
            <h1>Date</h1>
            <p>{publishedDate}</p>
          </div>
          <div>
            <h1>Tech</h1>
            <p>{usedTech}</p>
          </div>

          <OutboundLink href={`${githubLink}`} target="_blank" rel="noopener">
            <h1>GitHub</h1>
          </OutboundLink>

          {projectLink ? (
            <OutboundLink
              href={`${projectLink}`}
              target="_blank"
              rel="noopener"
            >
              <h1>See it live!</h1>
            </OutboundLink>
          ) : null}
        </Entities>
        {/* {documentToReactComponents(entry.json, options)} */}
        {extension === "mp4" ? (
          <div
            style={{
              position: "relative",
              paddingBottom: "46.95%",
              paddingTop: 25,
              height: 0,
            }}
          >
            <iframe
              title={title}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              src={imageVideo.file.url}
            />
          </div>
        ) : (
          <img src={imageVideo.file.url} alt={title} />
        )}
        <StyledEntities>
          <div>
            <h1>{hours}</h1>
            <p style={{ textAlign: "center" }}>work hours</p>
          </div>
          <div>
            <h1>{funnyText}</h1>
            <p style={{ textAlign: "center" }}>{funnyParagraph}</p>
          </div>
          <div>
            <h1>{codeLine}</h1>
            <p style={{ textAlign: "center" }}>lines of code</p>
          </div>
        </StyledEntities>
      </Container>
    </Layout>
  )
}

export default Project
